/**
 *
 * @param [arr] of questions without domain-based sorting
 * @returns object sorted out by domains as keys.
 */

import { ENFORCED_USER_KEY } from "../constants";


const segregateRequirementsForDomains = (arr) => {
  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      // if key is undefined, return the accumulator without any changes
      if (!key) {
        return { ...acc }
      }
      const curGroup = acc[key] ?? [];
      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  }
  return groupBy(arr, 'domain');
};

/*
 * expires-at in local storage is epoch seconds at which expiry has to occur
 * argument is number of seconds left
 * returns true if expired.
 */
const checkTokenExpired = expiryTimeSeconds => {
  const MS_TO_SECONDS = 1000;
  const timeNow = Math.floor(Date.now() / MS_TO_SECONDS);

  const expiryTime = JSON.parse(sessionStorage.getItem('expires-at'));
  const expiryTimeRemaining = expiryTime - timeNow;

  // check if token expiry time is less than 4 mins
  if (expiryTimeRemaining < expiryTimeSeconds) {
    return true;
  }
  return false;
};

const onSetTitle = title => {
  document.title = title;
}

/**
 *
 * @param {*} _ntid should be string between 4 and 20 chars length
 * and tally across the 2 regexps.
 */
const validateNtid = _ntid => {
  const ntIdErrorString = 'Please enter valid NTID.'
  const MIN_LENGTH = 3;
  const MAX_LENGTH = 20;
 
  if (!_ntid || _ntid.trim().length === 0) {
    console.log('empty ntid');
    return { ntIdError: true, ntIdErrorString: 'Please enter your NTID.' }
  }

  const ntid = _ntid.trim();
  if (ntid.length < MIN_LENGTH || ntid.length > MAX_LENGTH) {
    console.log('ntid length error');
    return { ntIdError: true, ntIdErrorString, }
  }

  const ntidRegex1 = new RegExp(/^[a-zA-Z._-]+[0-9]+[\w]*$/);
  const ntidRegex2 = new RegExp(/[a-zA-Z].*[a-zA-Z].*$/);
  if (!ntidRegex1.test(ntid) || !ntidRegex2.test(ntid)) {
    console.log('invalid ntid - expected at least 2 letters and 1 digit');
    return { ntIdError: true, ntIdErrorString }
  }
  return { ntIdError: false, ntIdErrorString: '' }
}

/*
 * standard email validation for @t-mobile.com domain, case insensitive.
 */
const validateEmail = _email => {
  if (!_email || _email.trim().length === 0) {
    console.log('empty email');
    return { emailError: true, emailErrorString: 'Please enter your Email.' }
  }
  const MAX_LENGTH = 40
  const email = _email.trim();
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[tT]-[mM][oO][bB][iI][lL][eE]\.[cC][oO][mM]$/);
  if (!emailRegex.test(email)) {
    console.log('invalid email');
    return { emailError: true, emailErrorString: 'Please enter valid Email.' }

  }
  if (email.length > MAX_LENGTH) {
    console.log('email exceeds 40 characters');
    return { emailError: true, emailErrorString: 'Please enter valid Email.' }
  }
  return { emailError: false, emailErrorString: '' }
}


// to remove, disable log on production and stg
const onRemoveLogs = () => {
  const protectedEnvironments = ['production', 'prod', 'prd', 'staging', 'stage', 'stg'];
  if (protectedEnvironments.includes(process.env.NODE_ENV)) {
    console.log = () => { /* do nothing */ };
    console.error = () => { /* do nothing */ };
  }
};


const removeIntakesWithNoFeature = (arr) => {
  return arr.filter(item => item.featureId)
};

const formAnsArray = (ans) => {
  return ans.reduce((acc, cur) => {
    acc[cur.questionId - 1] = cur.answer
    return [...acc];
  }, []);
}

const getNameFromTMobileEmail = (email) => {
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[tT]-[mM][oO][bB][iI][lL][eE]\.[cC][oO][mM]$/);
  if (!emailRegex.test(email)) {
    return '';
  }
  const name = email.split('@')[0];
  const nameArray = name.split('.');
  const firstName = nameArray[0];
  const lastName = nameArray[1].replace(/\d+$/, '')
  return `${firstName} ${lastName}`;
}

const updateAssetsArr =(arr)=>{
  const assetsByCategory ={};
  const newArr = [];
arr?.forEach((item, i)=>{
  const current = {...item};
if(assetsByCategory[item.assetType]) {
  current.isFirstOfType = false;
  assetsByCategory[item.assetType] = [...assetsByCategory[item.assetType], current];
} else {
  current.isFirstOfType = true;
  assetsByCategory[item.assetType] = [current];
}
newArr[i] = current;
});
return {newArr, assetsByCategory};
}

const removeSurveyDataFromLocal = ()=>{
  sessionStorage.removeItem('refreshed');
  // sessionStorage.removeItem('currentAsset');
  // sessionStorage.removeItem('activeAssetNumber');
  sessionStorage.removeItem('ansArr');
}

const isTokensValid = () => {
  const idToken = JSON.parse(sessionStorage.getItem('msal-tokens')).idToken.idToken;
  const accessToken= JSON.parse(sessionStorage.getItem('msal-tokens')).accessToken.accessToken;
  return accessToken && idToken;
}

const getEnforcedUserKey = (id) => {
  return `${ENFORCED_USER_KEY}-${id}`;
}

const storeToSession = (key, value) => {
  sessionStorage.setItem(key, value);
}

const getFromSession = (key, defaultValue) => {
  return sessionStorage.getItem(key) ?? defaultValue
}

export {
  segregateRequirementsForDomains,
  checkTokenExpired,
  onSetTitle,
  validateNtid,
  validateEmail,
  onRemoveLogs,
  removeIntakesWithNoFeature,
  formAnsArray,
  getNameFromTMobileEmail,
  updateAssetsArr,
  removeSurveyDataFromLocal,
  isTokensValid,
  getEnforcedUserKey,
  storeToSession,
  getFromSession,
}
